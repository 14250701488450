import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    BulkUpdateJofemarVisionEsPlusChannelProductVariantDocument,
    BulkUpdateJofemarVisionEsPlusChannelStockDocument,
    GetJofemarVisionEsPlusCollectionDocument,
    GetJofemarVisionEsPlusTraysDocument,
    GetJofemarVisionEsPlusTraysQuery,
    ResetJofemarVisionEsPlusDocument,
    SyncJofemarVisionEsPlusDocument,
} from '../../generated-types';
import {
    DataService,
    ModalService,
    NotificationService,
    PermissionsService,
    SharedModule,
    PageMetadataService,
} from '@vendure/admin-ui/core';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    EMPTY,
    finalize,
    interval,
    merge,
    Observable,
    of,
    Subject,
    timer,
} from 'rxjs';
import { debounceTime, groupBy, map, mergeMap, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { ProductVariantSelectDialogComponent } from '../product-variant-select-dialog/product-variant-select-dialog.component';
import { DeliverType } from '../../ui-types';

type Modes = 'home' | 'edit' | 'increase' | 'decrease';

@Component({
    selector: 'agroflex-channels',
    templateUrl: './jofemar-vision-es-plus-channel-list.component.html',
    styleUrls: ['./jofemar-vision-es-plus-channel-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class JofemarVisionEsPlusChannelListComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    devices$: Observable<
        {
            id: string;
            machineAddress: { espSerialNumber: string };
            isSyncing: boolean;
        }[]
    >;
    selectedDevice: {
        id: string;
        machineAddress: { espSerialNumber: string };
        isSyncing: boolean;
    };
    private refreshJofemar$ = new BehaviorSubject<void>(undefined);
    private loadJofemarTrays$ = new BehaviorSubject<void>(undefined);
    private itemsSubject = new BehaviorSubject<
        GetJofemarVisionEsPlusTraysQuery['jofemarVisionEsPlusTrays']['items']
    >([]);
    items$ = this.itemsSubject.asObservable();
    private updateStockSubject = new Subject<{ id: string; inStock: number }>();
    panels: { [key: string]: boolean } = {};
    private stockUpdates: { [key: string]: number } = {};
    private loadingStates: { [key: string]: boolean } = {};
    private previousStocks: { [key: string]: number } = {};
    @Input() mode: Modes = 'home';
    modeOptions = [
        {
            id: 'home',
            value: 'home',
            title: 'Bekijk voorraad',
            iconShape: 'home',
            iconDirection: '',
        },
        {
            id: 'increase',
            value: 'increase',
            title: 'Verhoog voorraad',
            iconShape: 'arrow',
            iconDirection: '',
        },
        {
            id: 'decrease',
            value: 'decrease',
            title: 'Verlaag voorraad',
            iconShape: 'arrow',
            iconDirection: 'down',
        },
        {
            id: 'edit',
            value: 'edit',
            title: 'Wijzig product',
            iconShape: 'pencil',
            iconDirection: '',
        },
    ];

    constructor(
        private readonly modalService: ModalService,
        private readonly dataService: DataService,
        private readonly notificationService: NotificationService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly permissionsService: PermissionsService,
        private readonly pageMetadataService: PageMetadataService,
    ) {}

    ngOnInit(): void {
        this.loadJofemarTrays$
            .pipe(
                switchMap(() => {
                    if (!this.selectedDevice) {
                        return EMPTY;
                    }

                    return this.dataService
                        .query(GetJofemarVisionEsPlusTraysDocument, {
                            options: {
                                filter: {
                                    jofemarVisionEsPlusId: {
                                        eq: this.selectedDevice.id,
                                    },
                                },
                            },
                        })
                        .mapSingle(data =>
                            data.jofemarVisionEsPlusTrays.items.filter(
                                ({ jofemarVisionEsPlusChannels }) => jofemarVisionEsPlusChannels.length > 0,
                            ),
                        );
                }),
            )
            .subscribe(items => this.itemsSubject.next(items));

        this.refreshJofemar$
            .pipe(
                switchMap(() =>
                    merge(
                        timer(0), // Emit immediately
                        interval(5000), // Continue emitting every 5 seconds
                    ).pipe(
                        switchMap(() =>
                            this.permissionsService.userHasPermissions(['ManageJofemarVisionEsPlusStock'])
                                ? this.dataService
                                      .query(GetJofemarVisionEsPlusCollectionDocument)
                                      .mapSingle(data => {
                                          this.devices$ = of(data.jofemarVisionEsPlusCollection.items);
                                          this.changeDetectorRef.markForCheck();
                                          if (data.jofemarVisionEsPlusCollection.items.length === 1) {
                                              this.selectDevice(data.jofemarVisionEsPlusCollection.items[0]);
                                          }
                                          return data.jofemarVisionEsPlusCollection.items;
                                      })
                                : of(null),
                        ),
                        takeWhile(
                            jofemarVisionEsPlus =>
                                jofemarVisionEsPlus !== null &&
                                jofemarVisionEsPlus.some(
                                    el => this.selectedDevice?.id === el.id && el.isSyncing,
                                ),
                            true,
                        ),
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe();

        this.updateStockSubject
            .pipe(
                groupBy(stockUpdate => stockUpdate.id),
                mergeMap(group$ =>
                    group$.pipe(
                        debounceTime(300),
                        switchMap(({ id, inStock }) => {
                            const input = {
                                ids: [id],
                                inStock,
                            };
                            this.loadingStates[id] = true;
                            this.changeDetectorRef.markForCheck();
                            return this.dataService
                                .mutate(BulkUpdateJofemarVisionEsPlusChannelStockDocument, {
                                    input,
                                })
                                .pipe(
                                    map(() => ({ id, inStock: input.inStock })),
                                    finalize(() => {
                                        delete this.previousStocks[id];
                                        this.loadingStates[id] = false;
                                        this.changeDetectorRef.markForCheck();
                                    }),
                                    catchError(() => {
                                        // If mutation fails, revert stock to previous value
                                        this.stockUpdates[id] = this.previousStocks[id];
                                        const revertedItems = this.itemsSubject.value.map(item => ({
                                            ...item,
                                            jofemarVisionEsPlusChannels: item.jofemarVisionEsPlusChannels.map(
                                                jofemarVisionEsPlusChannel =>
                                                    jofemarVisionEsPlusChannel.id === id
                                                        ? {
                                                              ...jofemarVisionEsPlusChannel,
                                                              inStock: this.previousStocks[id],
                                                          }
                                                        : jofemarVisionEsPlusChannel,
                                            ),
                                        }));
                                        this.itemsSubject.next(revertedItems);
                                        this.notificationService.error(
                                            'Er is een fout opgetreden bij het bijwerken van de voorraad. Probeer het opnieuw.',
                                        );
                                        this.loadingStates[id] = false;
                                        this.changeDetectorRef.markForCheck();
                                        return EMPTY;
                                    }),
                                );
                        }),
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    setMode(mode: Modes): void {
        this.mode = mode;
    }

    togglePanel(panel: string): void {
        this.panels[panel] = !this.panels[panel];
    }

    trackByJofemarVisionEsPlusTrayId(
        index: number,
        item: GetJofemarVisionEsPlusTraysQuery['jofemarVisionEsPlusTrays']['items'][0],
    ): string {
        return item.id;
    }

    trackByJofemarVisionEsPlusChannelId(
        index: number,
        item: GetJofemarVisionEsPlusTraysQuery['jofemarVisionEsPlusTrays']['items'][0],
    ): string {
        return item.id;
    }

    resetToFactorySettings(): void {
        combineLatest([
            this.modalService.dialog({
                title: 'agromaat.dialog.reset-agroflex.title',
                buttons: [
                    { label: 'Cancel', type: 'secondary' },
                    { label: 'Reset', type: 'danger', returnValue: true },
                ],
                body: 'agromaat.dialog.reset-agroflex.body',
            }),
        ])
            .pipe(
                switchMap(([response]) =>
                    response
                        ? this.dataService.mutate(ResetJofemarVisionEsPlusDocument, {
                              input: { jofemarVisionEsPlusId: this.selectedDevice.id },
                          })
                        : EMPTY,
                ),
            )
            .subscribe(result => {
                if (result.resetJofemarVisionEsPlus.success) {
                    this.notificationService.success('agromaat.dialog.reset-agroflex.success');
                } else {
                    this.notificationService.error('agromaat.dialog.reset-agroflex.failed');
                }
            });
    }

    syncJofemarVisionEsPlus(): void {
        combineLatest([
            this.modalService.dialog({
                title: 'agromaat.dialog.start-sync-agroflex.title',
                buttons: [
                    { label: 'Annuleren', type: 'secondary' },
                    { label: 'Synchroniseren', type: 'primary', returnValue: true },
                ],
                body: 'agromaat.dialog.start-sync-agroflex.body',
            }),
        ])
            .pipe(
                switchMap(([response]) =>
                    response
                        ? this.dataService.mutate(SyncJofemarVisionEsPlusDocument, {
                              input: { jofemarVisionEsPlusId: this.selectedDevice.id },
                          })
                        : EMPTY,
                ),
            )
            .subscribe(result => {
                if (result.syncJofemarVisionEsPlus.started) {
                    this.notificationService.success('agromaat.dialog.start-sync-agroflex.success');
                } else {
                    this.notificationService.error('agromaat.dialog.start-sync-agroflex.failed');
                }
                this.devices$ = this.devices$.pipe(
                    map(jofemarVisionEsPlus => ({
                        ...jofemarVisionEsPlus,
                        isSyncing: true,
                    })),
                );
                setTimeout(() => this.refreshJofemarVisionEsPlus(), 5000);
            });
    }

    refreshJofemarVisionEsPlus(): void {
        this.refreshJofemar$.next();
    }

    refreshJofemarVisionEsPlusTrays(): void {
        this.loadJofemarTrays$.next();
    }

    refresh() {}

    update({
        id,
        inStock,
        productVariant,
    }: {
        id: string;
        inStock: number;
        productVariant: { id: string };
    }): void {
        if (this.mode === 'edit') {
            const ids = [id];
            this.modalService
                .fromComponent(ProductVariantSelectDialogComponent, {
                    locals: {
                        cancellable: true,
                        deliverDevice: DeliverType.JOFEMAR_VISION_ES_PLUS,
                        excludeIds: productVariant ? [productVariant.id] : [],
                    },
                })
                .subscribe(id => {
                    if (id) {
                        this.dataService
                            .mutate(BulkUpdateJofemarVisionEsPlusChannelProductVariantDocument, {
                                input: {
                                    ids,
                                    productVariantId: id[0],
                                },
                            })
                            .subscribe(
                                result => {
                                    if (
                                        result.bulkUpdateJofemarVisionEsPlusChannelProductVariant[0]
                                            .__typename !== 'JofemarVisionEsPlusChannel'
                                    ) {
                                        this.notificationService.error('common.notify-update-error', {
                                            entity: 'AgroFlex kanaal',
                                        });
                                        return;
                                    }

                                    const updated =
                                        result.bulkUpdateJofemarVisionEsPlusChannelProductVariant[0];
                                    const updatedItems = this.itemsSubject.value.map(item => ({
                                        ...item,
                                        jofemarVisionEsPlusChannels: item.jofemarVisionEsPlusChannels.map(
                                            jofemarVisionEsPlusChannel =>
                                                jofemarVisionEsPlusChannel.id === updated.id
                                                    ? updated
                                                    : jofemarVisionEsPlusChannel,
                                        ),
                                    }));
                                    this.itemsSubject.next(updatedItems as any);
                                },
                                () =>
                                    this.notificationService.error('common.notify-update-error', {
                                        entity: 'AgroFlex kanaal',
                                    }),
                            );
                    }
                });
            return;
        }

        if (this.previousStocks[id] === undefined) {
            this.previousStocks[id] = inStock;
        }

        // Immediately update the local stock state
        this.stockUpdates[id] = Math.max(0, inStock + (this.mode === 'increase' ? 1 : -1));

        // Update the UI immediately
        const updatedItems = this.itemsSubject.value.map(item => ({
            ...item,
            jofemarVisionEsPlusChannels: item.jofemarVisionEsPlusChannels.map(jofemarVisionEsPlusChannel =>
                jofemarVisionEsPlusChannel.id === id
                    ? { ...jofemarVisionEsPlusChannel, inStock: this.stockUpdates[id] }
                    : jofemarVisionEsPlusChannel,
            ),
        }));
        this.itemsSubject.next(updatedItems);

        // Emit the event to trigger to debounce
        this.updateStockSubject.next({ id, inStock: this.stockUpdates[id] });
    }

    selectDevice(device: any) {
        if (this.selectedDevice && this.selectedDevice.id === device.id) {
            return;
        }
        this.pageMetadataService.setTitle(`AgroFlex ${device.machineAddress.port}`);
        this.selectedDevice = device;
        this.refreshJofemarVisionEsPlusTrays();
        this.refreshJofemarVisionEsPlus();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
